const smSlider = require('sm-slider');

const $fullWidthSlider = document.querySelector('.fullwidth-slider');
const options = {
    infinite: true,
    showEmptySlides: false
};

if($fullWidthSlider) {
    new smSlider($fullWidthSlider, options);
}

